/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
var T, E, L, I, A, _, R, N, S;
!function (T) {
  T[T.FILL = 0] = "FILL", T[T.LINE = 1] = "LINE", T[T.MARKER = 2] = "MARKER", T[T.TEXT = 3] = "TEXT", T[T.LABEL = 4] = "LABEL";
}(T || (T = {})), function (T) {
  T[T.NONE = 0] = "NONE", T[T.MAP = 1] = "MAP", T[T.LABEL = 2] = "LABEL", T[T.LABEL_ALPHA = 4] = "LABEL_ALPHA", T[T.HITTEST = 8] = "HITTEST", T[T.HIGHLIGHT = 16] = "HIGHLIGHT", T[T.CLIP = 32] = "CLIP", T[T.DEBUG = 64] = "DEBUG", T[T.NUM_DRAW_PHASES = 9] = "NUM_DRAW_PHASES";
}(E || (E = {})), function (T) {
  T[T.SIZE = 0] = "SIZE", T[T.COLOR = 1] = "COLOR", T[T.OPACITY = 2] = "OPACITY", T[T.ROTATION = 3] = "ROTATION";
}(L || (L = {})), function (T) {
  T[T.MINMAX_TARGETS_OUTLINE = 128] = "MINMAX_TARGETS_OUTLINE", T[T.SCALE_TARGETS_OUTLINE = 256] = "SCALE_TARGETS_OUTLINE", T[T.FIELD_TARGETS_OUTLINE = 512] = "FIELD_TARGETS_OUTLINE", T[T.UNIT_TARGETS_OUTLINE = 1024] = "UNIT_TARGETS_OUTLINE";
}(I || (I = {})), function (T) {
  T[T.SPRITE = 0] = "SPRITE", T[T.GLYPH = 1] = "GLYPH";
}(A || (A = {})), function (T) {
  T[T.DEFAULT = 0] = "DEFAULT", T[T.SIMPLE = 1] = "SIMPLE", T[T.DOT_DENSITY = 2] = "DOT_DENSITY", T[T.OUTLINE_FILL = 3] = "OUTLINE_FILL", T[T.OUTLINE_FILL_SIMPLE = 4] = "OUTLINE_FILL_SIMPLE", T[T.HEATMAP = 5] = "HEATMAP", T[T.PIE_CHART = 6] = "PIE_CHART";
}(_ || (_ = {})), function (T) {
  T[T.All = 0] = "All", T[T.Highlight = 1] = "Highlight", T[T.InsideEffect = 2] = "InsideEffect", T[T.OutsideEffect = 3] = "OutsideEffect";
}(R || (R = {})), function (T) {
  T[T.BATCHING = 0] = "BATCHING", T[T.STRICT_ORDER = 1] = "STRICT_ORDER", T[T.STRICT_MARKERS_AND_TEXT = 2] = "STRICT_MARKERS_AND_TEXT";
}(N || (N = {})), function (T) {
  T[T.FILL = 0] = "FILL", T[T.LINE = 1] = "LINE", T[T.MARKER = 2] = "MARKER", T[T.TEXT = 3] = "TEXT";
}(S || (S = {}));
export { N as FeatureBatchingStrategy, R as FeatureSelection, S as FeatureSymbologyDrawOrder, A as MosaicType, L as VVBinding, E as WGLDrawPhase, T as WGLGeometryType, _ as WGLSymbologyType, I as WGLVVTarget };