/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { JSONMap as t } from "../../../../core/jsonMap.js";
import e from "../../engine/webgl/effects/highlight/HighlightGradient.js";
import { MultiHighlightGradient as i } from "../../engine/webgl/effects/highlight/MultiHighlightGradient.js";
import { getFeaturePipelineState as n } from "../../engine/webgl/shaderGraph/techniques/featureTechniqueUtils.js";
const s = new t({
  esriGeometryPoint: "point",
  esriGeometryMultipoint: "multipoint",
  esriGeometryPolyline: "polyline",
  esriGeometryPolygon: "polygon",
  esriGeometryMultiPatch: "multipatch",
  mesh: "mesh"
});
function r(t) {
  return s.toJSON(t);
}
function l(t, e, i) {
  const n = [],
    s = [];
  let r = 0,
    l = 0;
  for (const o of t) {
    const t = l;
    let a = o[0][0],
      h = o[0][1];
    n[l++] = a, n[l++] = h;
    let p = 0;
    for (let e = 1; e < o.length; ++e) {
      const t = a,
        i = h;
      a = o[e][0], h = o[e][1], p += h * t - a * i, n[l++] = a, n[l++] = h;
    }
    e(p / 2), p > 0 ? (t - r > 0 && (i(r, t, n, s), r = t), s.length = 0) : p < 0 && t - r > 0 ? s.push(.5 * (t - r)) : l = t;
  }
  l - r > 0 && i(r, l, n, s);
}
function o(t) {
  const {
    bandCount: e,
    attributeTable: i,
    colormap: n,
    pixelType: s
  } = t.raster.rasterInfo;
  return 1 === e && (null != i || null != n || "u8" === s || "s8" === s);
}
function a(t, n) {
  return null == n ? (t?.destroy(), null) : ("single" === t?.type && Array.isArray(n) && (t.destroy(), t = null), "multi" !== t?.type || Array.isArray(n) || (t.destroy(), t = null), t || (t = Array.isArray(n) ? new i() : new e()), Array.isArray(n) ? "multi" === t.type && t.setHighlightOptions(n) : "single" === t.type && t.setHighlightOptions(n), t);
}
function h(t, e, i, s) {
  const {
      painter: r,
      highlightGradient: l
    } = t,
    {
      highlight: o
    } = r.effects;
  if (!l) return;
  const a = t.passOptions,
    h = l.getReasonsWithGradients();
  for (let p = 0; p < h.length; p++) {
    const l = {
      ...t,
      passOptions: {
        type: "highlight",
        activeGradient: null != s ? h[s].activeGradient : h[p].activeGradient,
        activeReasons: h[p].activeReasons,
        stepType: "burn",
        highlightAll: e
      }
    };
    if (o.bind(l), i(l), p < h.length - 1) {
      let n = 0;
      for (let t = p + 1; t < h.length; t++) n |= h[t].activeReasons;
      i({
        ...t,
        passOptions: {
          type: "highlight",
          activeGradient: null != s ? h[s].activeGradient : h[p].activeGradient,
          activeReasons: n,
          stepType: "clear",
          highlightAll: e
        }
      });
    }
    const a = {
      ...t,
      passOptions: {
        type: "highlight",
        activeGradient: null != s ? h[s].activeGradient : h[p].activeGradient,
        activeReasons: h[p].activeReasons,
        stepType: "draw",
        highlightAll: e
      }
    };
    r.setPipelineState(n(t)), r.updatePipelineState(t.context), o.draw(a), o.unbind();
  }
  t.passOptions = a;
}
export { l as analyzeRings, o as canUseMajorityInterpolationOnDataSource, a as createOrReuseHighlightGradient, h as renderHighlight, r as toJSONGeometryType };