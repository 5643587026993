/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../Color.js";
import t from "../../core/Accessor.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as i } from "../../core/accessorSupport/decorators/subclass.js";
import { defaultColor as p, defaultHaloOpacity as l, defaultFillOpacity as c } from "./HighlightDefaults.js";
let e = class extends t {
  constructor(o) {
    super(o), this.color = p.clone(), this.haloOpacity = l, this.fillOpacity = c;
  }
  equals(o) {
    return this.color.equals(o.color) && (this.haloColor || this.color).equals(o.haloColor || o.color) && this.haloOpacity === o.haloOpacity && this.fillOpacity === o.fillOpacity;
  }
};
o([s({
  type: r
})], e.prototype, "color", void 0), o([s({
  type: r
})], e.prototype, "haloColor", void 0), o([s()], e.prototype, "haloOpacity", void 0), o([s()], e.prototype, "fillOpacity", void 0), e = o([i("esri.views.support.HighlightOptions")], e);
const a = e;
export { a as default };