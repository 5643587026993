/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "./HighlightGradient.js";
class s {
  constructor() {
    this.type = "multi", this.gradients = [];
  }
  setHighlightOptions(s) {
    for (let i = 0; i < s.length; i++) this.gradients[i] || (this.gradients[i] = new t()), this.gradients[i].setHighlightOptions(s[i].options);
    for (let t = s.length + 1; t < this.gradients.length; t++) this.gradients[t].destroy();
    this.gradients.length = s.length;
  }
  destroy() {
    for (const t of this.gradients) t.destroy();
  }
  getReasonsWithGradients() {
    let t = 1;
    const s = [];
    for (let i = 0; i < this.gradients.length; i++) {
      const e = this.gradients[i];
      s.push({
        activeReasons: t,
        activeGradient: e
      }), t <<= 1;
    }
    return s;
  }
}
export { s as MultiHighlightGradient };