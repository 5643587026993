/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import o from "../../Color.js";
const e = new o("cyan"),
  r = 1,
  l = .25,
  n = new o("black"),
  t = .4,
  a = .2,
  w = .25,
  c = "default",
  m = "temporary",
  p = new o("yellow"),
  y = 8;
export { e as defaultColor, l as defaultFillOpacity, r as defaultHaloOpacity, c as defaultHighlightName, w as defaultOccludedFactor, n as defaultShadowColor, a as defaultShadowDifference, t as defaultShadowOpacity, y as maximumHighlights, p as temporaryHighlightColor, m as temporaryHighlightName };