/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import o from "../../../../../../core/Logger.js";
import { textureBindingHighlight1 as i, maxHighlightReasons as t } from "../../definitions.js";
import { shadeTextureSize as e, sigma as l } from "./parameters.js";
import r from "../../../../support/HighlightOptions.js";
import { TextureWrapMode as h } from "../../../../../webgl/enums.js";
import { Texture as n } from "../../../../../webgl/Texture.js";
import { TextureDescriptor as s } from "../../../../../webgl/TextureDescriptor.js";
const a = () => o.getLogger("esri.views.2d.engine.webgl.painter.highlight.HighlightGradient");
function u(o, i) {
  i.fillColor[0] = o.color.r / 255, i.fillColor[1] = o.color.g / 255, i.fillColor[2] = o.color.b / 255, i.fillColor[3] = o.color.a, o.haloColor ? (i.outlineColor[0] = o.haloColor.r / 255, i.outlineColor[1] = o.haloColor.g / 255, i.outlineColor[2] = o.haloColor.b / 255, i.outlineColor[3] = o.haloColor.a) : (i.outlineColor[0] = i.fillColor[0], i.outlineColor[1] = i.fillColor[1], i.outlineColor[2] = i.fillColor[2], i.outlineColor[3] = i.fillColor[3]), i.fillColor[3] *= o.fillOpacity, i.outlineColor[3] *= o.haloOpacity, i.fillColor[0] *= i.fillColor[3], i.fillColor[1] *= i.fillColor[3], i.fillColor[2] *= i.fillColor[3], i.outlineColor[0] *= i.outlineColor[3], i.outlineColor[1] *= i.outlineColor[3], i.outlineColor[2] *= i.outlineColor[3], i.outlineWidth = (1 - o.haloBlur) * o.haloWidth, i.outerHaloWidth = o.haloBlur * o.haloWidth / 2, i.innerHaloWidth = o.haloBlur * o.haloWidth / 2, i.outlinePosition = 0;
}
const d = [0, 0, 0, 0];
class g {
  constructor() {
    this.type = "single", this._highlightOptions = new r(), this._convertedHighlightOptions = {
      fillColor: [0, 0, 0, 0],
      outlineColor: [0, 0, 0, 0],
      outlinePosition: 0,
      outlineWidth: 0,
      innerHaloWidth: 0,
      outerHaloWidth: 0
    }, this._optionsShadeTexKey = "", this._texelData = new Uint8Array(4 * e), this._minMaxDistance = [0, 0];
  }
  setHighlightOptions(o) {
    this._highlightOptions = o;
  }
  applyHighlightOptions(o, t) {
    this._updateGradientTexture(o), o.bindTexture(this._shadeTex, i), t.setUniform2fv("u_minMaxDistance", this._minMaxDistance);
  }
  destroy() {
    this._shadeTex?.dispose(), this._shadeTex = null;
  }
  getReasonsWithGradients() {
    return [{
      activeReasons: (1 << t) - 1,
      activeGradient: this
    }];
  }
  _updateGradientTexture(o) {
    const {
      optionsKey: i
    } = this._highlightOptions;
    if (i === this._optionsShadeTexKey) return;
    this._optionsShadeTexKey = i, u(this._highlightOptions, this._convertedHighlightOptions);
    const t = this._convertedHighlightOptions,
      r = t.outlinePosition - t.outlineWidth / 2 - t.outerHaloWidth,
      g = t.outlinePosition - t.outlineWidth / 2,
      f = t.outlinePosition + t.outlineWidth / 2,
      p = t.outlinePosition + t.outlineWidth / 2 + t.innerHaloWidth,
      C = Math.sqrt(Math.PI / 2) * l,
      c = Math.abs(r) > C ? Math.round(10 * (Math.abs(r) - C)) / 10 : 0,
      m = Math.abs(p) > C ? Math.round(10 * (Math.abs(p) - C)) / 10 : 0;
    let x;
    c && !m ? a().error("The outer rim of the highlight is " + c + "px away from the edge of the feature; consider reducing some width values or shifting the outline position towards positive values (inwards).") : !c && m ? a().error("The inner rim of the highlight is " + m + "px away from the edge of the feature; consider reducing some width values or shifting the outline position towards negative values (outwards).") : c && m && a().error("The highlight is " + Math.max(c, m) + "px away from the edge of the feature; consider reducing some width values.");
    const _ = [void 0, void 0, void 0, void 0];
    function w(o, i, t) {
      _[0] = (1 - t) * o[0] + t * i[0], _[1] = (1 - t) * o[1] + t * i[1], _[2] = (1 - t) * o[2] + t * i[2], _[3] = (1 - t) * o[3] + t * i[3];
    }
    const {
      _texelData: T
    } = this;
    for (let l = 0; l < e; ++l) x = r + l / (e - 1) * (p - r), x < r ? (_[0] = 0, _[1] = 0, _[2] = 0, _[3] = 0) : x < g ? w(d, t.outlineColor, (x - r) / (g - r)) : x < f ? [_[0], _[1], _[2], _[3]] = t.outlineColor : x < p ? w(t.outlineColor, t.fillColor, (x - f) / (p - f)) : [_[0], _[1], _[2], _[3]] = t.fillColor, T[4 * l] = 255 * _[0], T[4 * l + 1] = 255 * _[1], T[4 * l + 2] = 255 * _[2], T[4 * l + 3] = 255 * _[3];
    if (this._minMaxDistance[0] = r, this._minMaxDistance[1] = p, !this._shadeTex) {
      const i = new s();
      i.wrapMode = h.CLAMP_TO_EDGE, i.width = e, i.height = 1, this._shadeTex = new n(o, i);
    }
    this._shadeTex.updateData(0, 0, 0, e, 1, this._texelData);
  }
}
export { g as default };