/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { getMetersPerUnitForSR as t } from "../../../../../../core/unitUtils.js";
import { maxRepresentableInt as e, tileSize as i } from "../../definitions.js";
import { FeatureSelection as o } from "../../enums.js";
import { CompareFunction as n, StencilOperation as s } from "../../../../../webgl/enums.js";
const r = {
    color: {
      write: [!0, !0, !0, !0],
      blendMode: "composite"
    },
    depth: !1,
    stencil: {
      write: !1,
      test: {
        ref: t => t.stencilRef,
        compare: n.EQUAL,
        mask: 255,
        op: {
          fail: s.KEEP,
          zFail: s.KEEP,
          zPass: s.REPLACE
        }
      }
    }
  },
  l = {
    color: {
      write: [!0, !0, !0, !0],
      blendMode: "additive"
    },
    depth: !1,
    stencil: !1
  },
  a = {
    ...r,
    color: {
      write: [!0, !0, !0, !0],
      blendMode: "delete"
    }
  };
function c({
  pixelRatio: e,
  state: i,
  displayLevel: o,
  requiredLevel: n
}, s) {
  const r = 1 / 2 ** (o - s.key.level),
    l = 1 / 2 ** (n - s.key.level);
  return {
    displayMat3: i.displayMat3,
    displayViewMat3: i.displayViewMat3,
    displayViewScreenMat3: s.transforms.displayViewScreenMat3,
    viewMat3: i.viewMat3,
    tileMat3: s.transforms.tileMat3,
    displayZoomFactor: r,
    requiredZoomFactor: l,
    tileOffset: [s.x, s.y],
    currentScale: i.scale,
    currentZoom: o,
    metersPerSRUnit: t(i.spatialReference),
    rotation: i.rotation,
    pixelRatio: e
  };
}
function p(t) {
  return "highlight" === t.passOptions?.type;
}
function f(t) {
  return "hittest" === t.passOptions?.type;
}
function u(t) {
  if (!f(t)) return null;
  const {
    position: e,
    distance: i,
    smallSymbolDistance: o,
    smallSymbolSizeThreshold: n
  } = t.passOptions;
  return {
    position: e,
    distance: i,
    smallSymbolDistance: o,
    smallSymbolSizeThreshold: n
  };
}
function d(t) {
  if (!p(t)) return null;
  const {
    activeReasons: e,
    highlightAll: i
  } = t.passOptions;
  return {
    activeReasons: e,
    highlightAll: i ? 1 : 0
  };
}
function m(t, e, i) {
  const o = {};
  for (const n in i) i[n] instanceof Function ? o[n] = i[n](t, e) : o[n] = i[n];
  return o;
}
function h(t, e) {
  const {
    attributeView: i,
    context: o
  } = t;
  return {
    storage: i.getUniforms(o),
    view: c(t, e),
    hittestRequest: u(t),
    highlight: d(t)
  };
}
function y(t) {
  return {
    inside: t.selection === o.InsideEffect,
    outside: t.selection === o.OutsideEffect
  };
}
function M(t) {
  return f(t) ? l : p(t) && "clear" === t.passOptions.stepType ? a : r;
}
function w(t) {
  const {
      row: o,
      col: n
    } = t.key,
    s = n * i,
    r = o * i;
  return {
    tileOffsetFromLocalOrigin: [s % e, r % e],
    maxIntsToLocalOrigin: [Math.floor(s / e), Math.floor(r / e)]
  };
}
export { M as getFeaturePipelineState, h as getFeatureUniforms, w as getLocalTileOffset, y as getSelectionDefines, c as getViewUniforms, p as isHighlight, f as isHittest, m as resolveDynamicUniforms };