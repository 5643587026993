/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function e() {
  const e = new Float32Array(9);
  return e[0] = 1, e[4] = 1, e[8] = 1, e;
}
function r(e) {
  const r = new Float32Array(9);
  return r[0] = e[0], r[1] = e[1], r[2] = e[2], r[3] = e[3], r[4] = e[4], r[5] = e[5], r[6] = e[6], r[7] = e[7], r[8] = e[8], r;
}
function t(e, r, t, n, o, a, c, u, l) {
  const f = new Float32Array(9);
  return f[0] = e, f[1] = r, f[2] = t, f[3] = n, f[4] = o, f[5] = a, f[6] = c, f[7] = u, f[8] = l, f;
}
function n(e, r) {
  return new Float32Array(e, r, 9);
}
const o = Object.freeze(Object.defineProperty({
  __proto__: null,
  clone: r,
  create: e,
  createView: n,
  fromValues: t
}, Symbol.toStringTag, {
  value: "Module"
}));
export { r as clone, e as create, n as createView, t as fromValues, o as m };