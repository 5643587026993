/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import { property as t } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as r } from "../../../core/accessorSupport/decorators/subclass.js";
import s from "../../support/HighlightOptions.js";
var i;
let h = i = class extends s {
  constructor(o) {
    super(o), this.haloWidth = 2.1, this.haloBlur = .8 / this.haloWidth;
  }
  equals(o) {
    return super.equals(o) && this.haloWidth === o.haloWidth && this.haloBlur === o.haloBlur;
  }
  get optionsKey() {
    return `${this.color};${this.haloColor};${this.haloOpacity};${this.fillOpacity};${this.haloWidth};${this.haloBlur}`;
  }
  clone() {
    return new i({
      color: this.color.clone(),
      haloColor: this.haloColor?.clone(),
      haloOpacity: this.haloOpacity,
      fillOpacity: this.fillOpacity,
      haloWidth: this.haloWidth,
      haloBlur: this.haloBlur
    });
  }
};
o([t()], h.prototype, "haloWidth", void 0), o([t()], h.prototype, "haloBlur", void 0), h = i = o([r("esri.views.2d.support.HighlightOptions")], h);
const l = h;
export { l as default };